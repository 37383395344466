.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  width: 270px;
  margin-bottom: 50px;
}
/* rgb(245, 247, 247) */
/* #282c34 */
.App-header {
  background-color: rgb(245, 247, 247);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
